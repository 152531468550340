// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Print
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-no-qualifying-type, selector-no-type */

/* These styles are intended to be global */

// Vendor

@import "../../vendor/_gutenberg";

// Custom

a[href^="//"] {
    &::after {
        content: " (" attr(href) ")";
        font-size: 80%;
    }
}
